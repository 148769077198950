.MuiPagination-root {
  .MuiPaginationItem-root {
    line-height: 1;
  }
}

.Button-Line {
  > button:not(:last-child) {
    margin-right: 12px;
  }
  > a:not(:last-child) {
    margin-right: 12px;
  }
}

.Card-Container {
  border-radius: 8px !important;
  filter: drop-shadow(0px 1px 1px #0004);
}

.Card-Container.MuiCard-root {
  &:hover {
    transition: all 0.2s ease 0s;
    padding: 8px;
    margin: -8px;
    box-shadow: #0004 0px 0px 8px 0px;
  }

  .MuiCardMedia-root {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.MuiFormGroup-root {
  .MuiFormLabel-root {
    font-size: 1.2rem;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    font-size: 0.9rem;
  }
  .MuiFormHelperText-root {
    font-size: 0.85rem;
  }

  .MuiFormControl-root {
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
  }
}

.MuiDialog-container {
  padding: 6px;

  .MuiDialog-paper:not(.MuiPickersModal-dialogRoot) {
    margin-bottom: 120px;
    padding: 24px;
  }

  .MuiDialog-paperWidthMd {
    width: 720px;
  }
}

.slick-prev::before,
.slick-next::before {
  font-size: 40px;
}

.slick-slider .slick-next {
  right: 30px !important;
}
