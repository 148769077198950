$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.campain-page {
  padding-bottom: 20px;
  .banner {
    width: 100%;
    // height: calc(100vh - 200px);
    position: relative;
  }
  .banner-img {
    width: 100%;
    object-fit: cover;
    @include mobile() {
      height: 300px;
    }
    @include desktop() {
      max-height: 600px;
    }
  }
  .item-slider {
    margin: 20px 30px;
    @include desktop() {
      margin: 50px 80px;
    }
    //   .image-set {
    //       width: 1000px;
    //   }
    img {
      width: 540px;
      height: 210px;
      cursor: pointer;
      // object-fit: cover;
    }
    .text-campain {
      font-size: 32px;
      font-weight: 700;
    }
    .text-soldout {
      position: absolute;
      //   right: 30%;
      top: 45%;
      // left: 50%;
      font-size: 16px;
      font-weight: 700;
      margin-left: 250px;
      // transform: translate(-50%, -50%);
      color: white;
    }
  }
}
.campaign-slide-list {
  max-width: 1280px;
  margin: 0 auto !important;
}
