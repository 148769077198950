.legend-open {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  backdrop-filter: blur(34px);
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .name {
    font-weight: 700;
    font-size: 25px;
    color: #fff;
    margin-top: -20px;
  }
  .group-btn-box {
    font-size: 16px;
    button {
      width: 210px;
      height: 41px !important;
    }
    button.view {
      background: #ffca42;
      color: #242424;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.level {
  color: #242424;
  font-size: 12px;
  height: 28px;
  width: 95px;
  border-radius: 2px;
  background-color: #fff7dc;
  position: relative;
  text-align: center;
  line-height: 28px;
  img {
    position: absolute;
    left: -16px;
    top: -2px;
  }
  .level-value {
    margin-right: -12px;
  }
}
.level1 {
  text-align: center;
}

.item-open {
  width: 250px;
  margin-top: 5%;
  margin-bottom: 50px;
  .item {
    border: 1px solid #dde3f2;
    padding: 10px 10px 20px 10px;
    background: #f0e4cf;
    border-radius: 4px;
    position: relative;
  }
}

.level img:hover {
  transform: scale(1px);
}

///
.item {
  border: 1px solid rgba(221, 227, 242, 1);
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  position: relative;
  min-height: 290px;
  h5 {
    font-size: 16px;
  }
  .crossbar {
    width: 100%;
    border: 1px solid #f6f6f6;
  }
  .title-card {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #242424;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
  .price {
    margin-top: 10px;
  }
  .text-overlay {
    font-size: 14px;
    line-height: 28px;
    color: #767676;
  }
  .popup-in-img {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #ffffff;
    border: 1px solid #dde3f2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 10px;
    font-size: 12px;
  }
  .name-product {
    font-weight: 700;
    font-size: 16px;
  }
  .image-card {
    border-radius: 4px;
    margin-bottom: 10px;
    height: 270px;
    object-fit: cover;
  }
  .icon-crypt {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .text-coin {
    font-size: 14px !important;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .time {
    width: 25px !important;
    height: 22px !important;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    margin-left: 3px;
    padding: 1px 3px;
  }
  .name-coin {
    font-size: 14px;
    font-weight: 400;
  }
}

.item-top-seller {
  padding: 0px;
  border-radius: 8px;
  .card-text {
    margin-bottom: 10px;
    .name-product {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 19px;
      color: #242424;
    }
    .row-coin {
      display: flex;
      justify-content: space-between;
    }
  }
  .image-card {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-radius: 8px 8px 0 0;
  }
  .card-body {
    padding: 0 10px 20px 10px !important;
  }
  .dash {
    margin: 15px 0 !important;
  }
}

.legend-class {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    radial-gradient(178.04% 84.86% at 50% 100%, rgba(255, 202, 66, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .btn-lb {
    background: #947c3e !important;
    color: #fff;
  }
  .item-open {
    .item {
      box-shadow: 11px 7px 65px rgba(231, 171, 6, 0.867);
    }
  }
}

.epic-class {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    radial-gradient(178.04% 84.86% at 50% 100%, rgba(86, 27, 211, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .btn-lb {
    background: #452e75 !important;
    color: #fff;
  }
  .item-open {
    .item {
      box-shadow: 11px 7px 65px rgba(100, 15, 249, 0.867);
    }
  }
}

.super-rare {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    radial-gradient(178.04% 84.86% at 50% 100%, rgba(97, 196, 247, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .btn-lb {
    background: #5288a4 !important;
    color: #fff;
  }
  .item-open {
    .item {
      box-shadow: 11px 7px 65px rgba(8, 162, 230, 0.9);
    }
  }
}

.rare {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    radial-gradient(178.04% 84.86% at 50% 100%, rgba(41, 171, 78, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  .btn-lb {
    background: #366f46 !important;
    color: #fff;
  }
  .item-open {
    .item {
      box-shadow: 11px 7px 65px rgba(21, 220, 73, 0.867);
    }
  }
}

.common {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    radial-gradient(178.04% 84.86% at 50% 100%, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  border: 11px solid #a6a6a6;
  .btn-lb {
    background: #939393 !important;
    color: #fff;
  }
  .item-open {
    .item {
      box-shadow: 11px 7px 65px rgba(241, 237, 237, 0.867);
    }
  }
}

.loading-class {
  background: #c4c4c4;
  background: rgba(18, 9, 9, 0.31);
  border: 11px solid #a6a6a6;
  .loading-image {
    background-color: #e3e3e3;
    min-height: 240px;
  }
  .btn-lb {
    background: #939393 !important;
    color: #fff;
  }
  .item-open {
    .item {
      background: white;
      // box-shadow: 11px 7px 65px rgba(241, 237, 237, 0.867)
    }
  }
}

.loading-video {
  position: relative;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}