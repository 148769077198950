.item-detail-page {
  background-color: #f9f9f9;
  font-size: 14px;
  padding-top: 30px;
  color: 585858;
  .card {
    border-radius: 1px;
  }
  .container {
    width: 100%;
    max-width: 1200px;
  }
}

.detail-item-box {
  .image-item {
    width: 100%;
    img {
      width: 100%;
      border-radius: 8px;
    }
    > div {
      height: 100%;
      // background-size: cover;
      border-radius: 8px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);      
    }
  }
  .detail-item-infor {
    .card-itembox {
      box-shadow: 0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05);
      height: 100%;
    }
    .title {
      flex-direction: column;
      margin-bottom: 21px;
      .subject {
        font-weight: 600;
        color: #17204D;
        font-size: 36px;
        line-height: 44px;
        max-height: 88px;
        overflow: hidden;
      }
      .rarity_amount{
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .available {
        padding-bottom: 10px;
        border-bottom: 1px solid #e3e3e3;
      }
      .club {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: #5243AA;
      }
    }
    .item-content {
      .description-title {
        font-size: 16px;
        color: #17204d;
        line-height: 1.5em;
        font-weight: 600;
        padding-bottom: 10px;
      }
      .content{
        color: #7A7F9A;
      }
    }
    .page-infor {
      margin-bottom: 10px;
      .name-follow {
        margin-left: 10px;
        .name {
          font-size: 16px;
        }
      }
    }
    // .item-content {
    //     min-height: 150px;
    // }
    .price-product {
      .price-txt {
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        font-weight: normal;
      }
      .price-value {
        margin-left: 0px !important;
        display: flex;
        flex-direction: column;
        color: #c8aa5d;
        
      }
      .price-number {
        font-size: 24px;
        line-height: 32px;
        font-weight: normal !important;
      }
      .price-sub-number {
        text-align: end;
        font-weight: 400;
        font-size: 12px;
        color: #949496;
      }
    }
  }
}

.f-700 {
  font-weight: 700;
}

.c-24 {
  color: #242424;
}

.description-and-history {
  margin-top: 20px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  > div > div {
    height: 100%;
  }
  .description-card {
    background-color: white;
    border-radius: 8px;
    padding: 30px 20px !important;
    border: 1px solid #eaecef;
    box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);

    .description-text {
      font-size: 20px;
      margin-bottom: 18px;
    }
    .box-infor-des {
      margin-bottom: 17px;
      .name-box {
        margin-left: 10px;
      }
      .name {
        font-size: 16px;
      }
    }
    .description-content {
      .txt {
        color: #000;
      }
      span {
        line-height: 28px;
      }
      .rarity {
        background-color: #f8e600;
        /* height: 30px; */
        display: inline-block;
        padding: 0 10px;
        border-radius: 2px;
        font-weight: 600;
      }
      .content {
        margin-top: 20px;
      }
    }
  }
  .history-card {
    padding: 10px 20px 30px 20px;
    height: 100%;
    max-height: 415px;
    overflow-y: auto;
    box-shadow: 0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05);
    background-color: white;
    .history-title {
      border-bottom: 1px solid #e3e3e3;
      font-weight: 600;
      text-transform: uppercase;
      span {
        font-size: 16px;
        font-weight: 600;
        color: #242424;
        display: inline-block;
        height: 40px;
        text-align: center;
        width: 80px;
        border-bottom: 2px solid #242424;
      }
    }
    .itemSaleHistory {
      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        display: none;
      }
      .action,
      .price-usdc {
        font-weight: 600;
        font-size: 12px;
      }
      .time {
        font-size: 12px;
        color: #949496;
      }
      .ant-timeline-item {
        margin-left: -20px !important;
      }
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none !important;
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-tab-active button {
      background-color: #201934;
      color: white;
    }
    .ant-tabs-content-holder {
      overflow-y: auto;
      height: 310px;
    }

    .ant-tabs-content-holder::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // border-radius: 100px;
      background-color: #E2E8F0;
      width: 4px;
      margin-bottom: -170px;
    }

    .ant-tabs-content-holder::-webkit-scrollbar {
      width: 4px;
      background-color: #E2E8F0;
    }

    .ant-tabs-content-holder::-webkit-scrollbar-thumb {
      border-radius: 8px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #4A5568;
      border-bottom: 170px solid #E2E8F0 /*or any height and color you want*/;
    }
    .ant-tabs-content-holder::-webkit-scrollbar-thumb:hover {
      background-color: #cfb675;
    }
    .ant-tabs-content-holder::-webkit-scrollbar-track-piece{
      min-height: 250px;
    }    
  }
}

.buy {
  margin-bottom: 20px;
}