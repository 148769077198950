.drop-item-div {
  position: relative;
  float: left;
  width: 100%;
  .text-title {
    font-size: 29px;
    font-weight: bold;
    line-height: 40px;
  }
  .card {
    background: transparent !important;
    border: unset !important;
  }
  .card-body {
    padding: 12px 0px !important;
  }
  .item:hover {
    transition: all 0.3s ease 0s;
    box-shadow: #0004 0px 0px 3px 0px;
  }
  .item {
    padding: 10px 10px 20px 10px !important;
    border: 1px solid #dde3f2;
    border-radius: 8px;
    height: calc(100% - 20px);
    box-shadow: 0px 6px 20px rgba(32, 44, 55, 0.15);

    .item-card {
      .card-body {
        .dash {
          width: 100%;
          border: 1px solid #f6f6f6;
        }
        .card-text {
          font-size: 14px;
          line-height: 19px;
          color: #949496;
          margin: 10px 0;
          .name-product {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: #242424;
          }
        }
      }
      img {
        box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
        margin-bottom: 10px;
        border-radius: 8px;
      }
    }
  }
  .ant-col,
  .ant-list-item {
    height: 100% !important;
  }
}

.popup {
  z-index: 1;
  background: rgb(0 0 0 / 73%);
  padding: 10vh 15vw 10vh 15vw;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 901;
  .btn-cancle {
    position: fixed;
    top: 60px;
    right: calc(15vw - 40px);
    z-index: 3;
    font-size: 24px;
    cursor: pointer;
    font-weight: 600;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    span{
      color: white;
    }
  }
  img {
    border-radius: 8px;
  }
}

@media (max-width: 448px) {
  .popup {
    padding: 10px;
    .btn-cancle {
      top: 85px;
      right: 30px;
    }
  }
}
