.ant-notification {
  z-index: 1301 !important;
}

.ant-dropdown {
  z-index: 890 !important;
}

.ant-message {
  top: 68px !important;

  .ant-message-custom-content {
    font-size: 1rem;
  }
}

.ant-popover-message-title {
  font-size: 1rem;
}

.ant-upload.ant-upload-drag {
  border-radius: 4px !important;
  border-width: 2px !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  left: calc(50% - 20px);
  margin: 0px;
  margin-top: -20px;
}

.ant-image-preview-operations {
  height: 60px;
}
.ant-image-preview-operations-operation {
  border: 1px solid silver;
  background-color: #8c8c8c;
  border-radius: 6px;
  padding: 8px 10px;
  margin-left: 0px;
  margin-right: 12px;
}
.ant-image-preview-operations-operation:hover {
  color: #cfb675;
}
body {
  a {
    color: #123456;
  }
  a:hover {
    color: #254663;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

.slick-list {
  overflow: hidden;
  padding: 0px !important;
}
