$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.count-down {
  // position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 612px !important;
  padding: 20px;
  @include tablet() {
    top: 20%;
    left: 10%;
    width: 80%;
    height: 70.5%;
    font-size: 10px;
  }
  @include desktop() {
    top: 10%;
    width: 40%;
    left: 20%;
  }
  .title {
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    @include mobile {
      font-size: 28px;
      line-height: 43px;
    }
    @include desktop() {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 0.6;
  }
  .sub-artist {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.5;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  }
  .sub-total {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.6;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
    margin-bottom: 36px;
  }
  .campaign-start {
    width: 400px;
    text-align: center;
    background: #404040bd;
    padding: 20px 30px;
    border-radius: 5px;
  }
  .sold-out {
    width: unset;
    text-align: center;
    background: #404040bd;
    padding: 15px 30px;
    font-size: 36px;
    border: 2px solid white;
    border-radius: 40px;
    .sub-title-soldout {
      font-size: 20px;
      color: white !important;
    }
  }
  .time-countdown {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    .item-time {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #ffffff7a;
      .box-time {
        background-color: #fff;
        padding: 7px 10px;
        width: 59px;
        height: 51px;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: #242424;
        margin-bottom: 5px;
      }
    }
    .dot {
      color: #fff;
      font-size: 24px;
      padding-top: 6px;
    }
  }
  .btn-see-more {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    width: 140px;
    height: 48px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #242424;
    cursor: pointer;
    background: linear-gradient(31.65deg, #deb856 6.17%, #fde29b 93.12%);
    img {
      width: 20px;
    }
  }
}
.countdown-image{
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}