$image-width-box: 180px;
$image-width-box-sm: 90px;
.header-box-profile {
  color: #ffffff;
  width: 100%;
  position: relative;
  .header-box {
    width: 100%;
    height: 230px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-size: cover;
  }
  .box-img {
    margin-bottom: 5px;
    width: 37px;
    height: 37px;
    border: 3px dotted;
    border-bottom: none;
    position: relative;
    cursor: b;
    img {
      vertical-align: middle;
      border-style: none;
      position: absolute;
      bottom: -1px;
      right: -4px;
    }
  }
  .replace-cover {
    .replace-text {
      font-weight: 700;
      font-size: 20px;
    }
    .optimize {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
    }
  }
}

.header-box-profile {
  .replace-text {
    font-weight: 700;
    font-size: 20px;
  }
  .optimize {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
}

.header-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.image-select {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .circle-boder {
    background-image: linear-gradient(#bb00ff, #c700ff);
    // background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0.33, #743ad5), color-stop(0.67, #d53a9d));
    border: 4px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .circle-boder-2 {
    background-color: #fff;
    border: 6px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.image-select-box {
  width: $image-width-box;
  height: $image-width-box;
}

.profile-image {
  position: absolute;
  display: flex;
  bottom: -$image-width-box * 1/2;
  left: $image-width-box * 1/2;
  z-index: 9;
}

.bottom {
  height: $image-width-box * 1/2-10;
  width: $image-width-box - 20;
  border-bottom-left-radius: $image-width-box;
  border-bottom-right-radius: $image-width-box;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  flex-direction: column;
  top: $image-width-box * 1/2;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 24px;
    margin-top: -12px;
  }
  span {
    font-weight: 700;
    font-size: 13px;
  }
}

.infor-profile {
  .name {
    font-size: 32px;
    font-weight: 700px;
  }
  margin-left: 50px;
}

.profile-detail {
  margin-top: 20px;
  color: #585858;
  font-size: 14px;
  li {
    list-style: none;
  }
  .top-profile {
    display: flex;
    flex-direction: column;
    .infor {
      padding-left: 0;
      li.item {
        float: left;
        width: 95px;
        border: none;
        .number {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
  .social {
    margin-top: 20px;
    padding-left: 0;
    li {
      float: left;
      width: 40px;
    }
  }
  .content-profile {
    line-height: 36px;
  }
  .more {
    font-size: 12px;
    font-weight: 600;
    color: #242424;
  }
  .edit-btn {
    background: #d0b674;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    height: 40px;
    padding: 12px;
  }
}

.box-profile-main {
  .btn-close {
    position: relative;
    padding-right: 30px;
    border-radius: 50px;
    margin-right: 5px;
    .close-campaign {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  justify-content: space-between;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
  }
  .ant-space {
    align-items: start;
    gap: 8px;
    padding: 5px 10px;
    border: 1px solid #c1c4d0;
    border-radius: 30px;
  }
}

.box-profile-page .box-profile-main {
  .ant-space {
    border: unset !important;
  }
}

.ant-menu-sub {
  .ant-menu-item {
    float: left;
  }
  .ant-menu-item-selected {
    background: #e3e3e3 !important;
    color: #585858;
  }
  .ant-menu-item-active {
    color: #242424 !important;
    font-weight: 700 !important;
  }
}

.parent-item {
  font-size: 16px;
}

.ant-menu-item-active.parent-item {
  color: #242424 !important;
  border-bottom: 2px solid #1b0606 !important;
}

.menu-res {
  button {
    background: none;
    border: none;
    padding: 0;
    span {
      svg {
        fill: #242424 !important;
      }
    }
    &:hover {
      background: none;
    }
    &:active {
      background: none !important;
      border: none;
    }
    &:focus {
      background: none !important;
      border: none;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #1b0606 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #242424;
  font-weight: 700;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #242424 !important;
}

.ant-menu-submenu-selected {
  color: #242424 !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  counter-reset: #242424;
}

.ant-menu-submenu-title:hover {
  color: #242424 !important;
}

.ant-menu-item-selected.parent-item {
  color: #242424 !important;
  font-weight: 700;
  border-bottom: 2px solid #242424 !important;
}

.ant-menu {
  box-shadow: none;
}

@media (min-width: 1400px) {
  .parent-item {
    width: 165px;
  }
  .list-menu-res {
    display: none !important;
  }
}

//response-sive
@media (min-width: 1024px) and (max-width: 1400px) {
  .list-menu-res {
    display: none !important;
  }
  .parent-item {
    width: 120px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box-profile-menu {
    margin-top: 5px !important;
    position: absolute;
    z-index: 10;
    border: 1px solid #8080801f;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1) !important;
  }
  .button-res {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .profile-detail {
    margin-top: 40px;
    .div-hide {
      display: none;
    }
  }
  .image-select-box {
    width: $image-width-box-sm;
    height: $image-width-box-sm;
  }
  .profile-image {
    position: absolute;
    display: flex;
    bottom: -$image-width-box-sm * 1/2;
    left: $image-width-box-sm * 1/2;
  }
  .bottom {
    height: $image-width-box-sm * 1/2-10;
    width: $image-width-box-sm - 20;
    border-bottom-left-radius: $image-width-box-sm;
    border-bottom-right-radius: $image-width-box-sm;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: $image-width-box-sm * 1/2;
    justify-content: center;
    align-items: center;
    img {
      width: 19px;
      height: 16px;
      margin-top: -12px;
    }
    span {
      font-weight: 700;
      font-size: 13px;
      display: none;
    }
  }
  .profile-image {
    left: 10px;
  }
  .list-drop-items {
    margin-top: 20px !important;
  }
}

@media (max-width: 767px) {
  .list-drop-items {
    margin-top: 10px;
  }
  .box-profile-menu {
    margin-top: 5px;
    position: absolute;
    z-index: 10;
    border: 1px solid #8080801f;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1) !important;
  }
  .box-profile-main {
    margin-top: 20px;
  }
  .profile-detail {
    margin-top: 40px;
  }
  .image-select-box {
    width: $image-width-box-sm;
    height: $image-width-box-sm;
  }
  .profile-image {
    position: absolute;
    display: flex;
    bottom: -$image-width-box-sm * 1/2;
    left: $image-width-box-sm * 1/2;
  }
  .bottom {
    height: $image-width-box-sm * 1/2-10;
    width: $image-width-box-sm - 20;
    border-bottom-left-radius: $image-width-box-sm;
    border-bottom-right-radius: $image-width-box-sm;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: $image-width-box-sm * 1/2;
    justify-content: center;
    align-items: center;
    img {
      width: 19px;
      height: 16px;
      margin-top: -12px;
    }
    span {
      font-weight: 700;
      font-size: 13px;
      display: none;
    }
  }
  .profile-image {
    left: 10px;
  }
}

.bkl-filter-wrapper * {
  margin: unset;
}

.bkl-filter-wrapper {
  background: #fff;
  box-shadow: 0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px 15px 40px 15px;
  width: 20%;
  max-width: 308px;
  .bkl-filter-title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .ant-collapse-header {
    font-weight: 600;
    border-bottom: 1px solid #8080803b;
    padding-bottom: 5px !important;
  }
  .ant-collapse-content {
    padding: 15px 0px;
  }
  .ant-radio-group {
    width: 100%;
  }
  .ant-radio-wrapper {
    width: 50%;
    margin-bottom: 10px;
    margin-right: unset;
  }
  .ant-slider {
    margin-top: 10px 0px;
  }
  .ant-slider-rail {
    background: #0052c5;
  }
  .ant-slider-step {
    background: #e2e8f0;
  }
  .ant-slider-handle {
    background: #0052c5;
  }
  .ant-checkbox-wrapper {
    // width: 50% !important;
    margin: unset !important;
    margin-bottom: 10px !important;
  }
  .ant-collapse-header {
    padding: 20px 0px !important;
  }
  .ant-input-group {
    margin-bottom: 10px !important;
  }
  .ant-select-selector {
    border: none !important;
  }
  .ant-collapse-content-box {
    padding: unset !important;
  }
  .ant-select-selector {
    background-color: transparent !important;
  }
  .Label_To {
    text-align: center;
    width: 100%;
    display: block;
    line-height: 38px;
    color: #7a7f9a;
  }
}

.bkl-filter-wrapper > div > div > div > div,
.bkl-filter-wrapper > div > div,
.bkl-filter-wrapper > div > div > div,
.bkl-filter-wrapper > div {
  background: transparent !important;
  padding: 0px;
  margin: unset;
}

.select-collection > div {
  border: none !important;
}

.blk-content-wrapper {
  width: 80%;
  padding: 0 20px;
  > div {
    padding: unset !important;
    margin: unset;
    margin-bottom: 10px;
  }
}

.blk-market-left {
  .title {
    text-transform: uppercase;
    color: #17204d;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
  }
}

.market-page {
  .container {
    max-width: 1300px;
  }
}

.btn-acctive {
  background: #201934 !important;
  color: #fff !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #c9a138 !important;
  border-color: #c9a138 !important;
}
