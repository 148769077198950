@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/antd';
@import 'styles/layout';
@import 'styles/components';
@import 'styles/bootstrap';
@import 'styles/responsive';
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display.ttf') format('truetype');
  font-weight: 400;
}

$button-color: #17204d;

html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: 'Segoe UI Variable', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-mark {
  background-color: #d0b674 !important;
  border-radius: 4px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #242424 !important;
  border-color: gray;
  &:hover {
    background-color: #eed699 !important;
    color: #242424 !important;
    outline: gray !important;
  }
}

.btn-mark-lg {
  border-radius: 4px;
  width: 100%;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #242424 !important;
  background: linear-gradient(31.65deg, #deb856 6.17%, #fde29b 93.12%);
  box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);

  &:hover {
    background-color: #eed699 !important;
    color: #242424 !important;
    border-color: gray;
  }
}

.btn-secondary {
  color: #17204d !important;
  background-color: unset !important;
  border: 1px solid transparent;
}

.btn {
  border: 1px solid transparent;
}

.btn-mark-wh {
  background: #fff !important;
  border-radius: 4px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  border-color: gray;
  color: #242424 !important;
  &:hover {
    color: #242424 !important;
    border-color: gray !important;
  }
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}
.row {
  padding: unset;
  margin: 0px;
}

.containerBottom {
  background-color: #cfb675;
  color: #e3e3e3;
  padding: 30px calc(50% - 720px);
  width: 100%;
  position: relative;
  float: left;
  min-height: 120px;
  background-image: url(./assets/images/footer-bg.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 7%;
}
.footer-menu {
  display: block;
}
.footer-menu h2,
.footer-contact h2 {
  width: 100%;
  position: relative;
  float: left;
  font-size: 20px;
  line-height: 1.3em;
  color: #242424;
  font-weight: 600;
}
.footer-menu a {
  width: 100%;
  position: relative;
  float: left;
  color: #242424;
  font-size: 14px;
  line-height: 2em;
}
.footer-contact a {
  position: relative;
  float: left;
  color: #242424;
  font-size: 14px;
  line-height: 2em;
  padding-right: 10px;
}
.footer-contact a div {
  position: relative;
  float: left;
}
.footer-contact a img {
  max-height: 30px;
}
h1,
h2,
h3,
h4,
span,
p,
div {
  word-break: break-word;
}
h4,
h5,
h6 {
  font-size: 14px !important;
  margin: 0px;
}
span {
  padding: 0px;
}
th,
tr,
td {
  word-break: break-all;
  padding: 5px !important;
  vertical-align: middle !important;
}
a {
  color: unset;
}

.drop-amount-item {
  border-radius: 10px !important;
  height: 20px !important;
  background-color: #f4f5f7 !important;
  font-size: 12px !important;
  border: 1px solid #dfe0e6;
  text-align: center;
  line-height: 18px;
  padding: 0px 10px;
  margin-bottom: unset;
}
.drop-name-item {
  font-size: 14px;
  font-weight: bold;
  color: #42496e;
  line-height: 20px;
  margin-bottom: unset;
}

.rarity_btn {
  border-radius: 18px !important;
  height: 22px !important;
  background-color: transparent !important;
  padding: 2px 10px;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

}
.a-bg-color-yellow {
  background: linear-gradient(31.65deg, #deb856 6.17%, #fde29b 93.12%);
}
.a-bg-color-gray {
  background: #ebecf0;
}
.a-button {
  box-shadow: 0px 1px 30px rgb(9 30 66 / 5%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #17204d;
  text-transform: uppercase;
  float: right;
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 100%;
}

.a-button-color {
  color: $button-color !important;
}

.rarity_legend {
  color: #ffd148 !important;
  border-color: #ffd148 !important;
}
.rarity_epic {
  color: #fc51ff !important;
  border-color: #fc51ff !important;
}
.rarity_super_rare {
  color: #61c4f7 !important;
  border-color: #61c4f7 !important;
}
.rarity_rare {
  color: #00e742 !important;
  border-color: #00e742 !important;
}
.rarity_common {
  color: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
}

.image-contain {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  cursor: pointer;
}
.square-image {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: unset !important;
  border: 1px solid rgb(241 241 241 / 85%);
  box-shadow: 0px 3px 8px -1px rgb(0 39 85 / 6%), 0px 2px 5px rgb(0 39 85 / 15%);
  border-radius: 8px;
  background-color: white;
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  img {
    box-shadow: unset !important;
    border-radius: unset !important;
  }
}
.square-image:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.popup-image{
  top: 5vh;
  text-align: center;
  .ant-modal-close{
    display: none;
  }
  .ant-modal-body{
    padding: 20px !important;
  }
  .ant-modal-close:hover{
    // background: #2a5c54;
    span{
      color: #cfb675;  
    }

    
  }
  img{
    max-width: 100%;
    object-fit: contain;
  }

}
.ant-modal-mask{
  background:rgb(0 0 0 / 70%) !important;
}
.count-down-sl>div{
  z-index: 3;
}
.countdown-image::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background:#00000014 !important;
  z-index: 2;
}
.countdown-image > div{
  z-index: 3;
}
