$background: #252827;
$color-primary: #4298c3;
$color-light: white;
$spacing: 50px;
$radius: 4px;
$date: 120px;
$dotborder: 4px;
$dot: 11px;
$line: 4px;
$font-title: 'Oswald', sans-serif;
$font-text: 'Source Sans Pro', sans-serif;

/* Base */

body {
  background: $background;
  font-size: 16px;
}

strong {
  font-weight: 600;
}

h1 {
  font-family: $font-title;
  letter-spacing: 1.5px;
  color: $color-light;
  font-weight: 100;
  font-size: 2.4em;
}

#content {
  text-align: center;
}

.content {
  height: 122px;
  overflow-y: auto;
  padding-right: 10px;
  text-align: justify;
}

.content::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4x;
  background-color: #E2E8F0;
}

.content::-webkit-scrollbar {
  width: 4px;
  background-color: #E2E8F0;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4a5568;
}
.detail-item-infor > div {
  flex: 1;
  .description {
    overflow-y: scroll;
    max-height: 122px;
    height: 122px;
    overflow-y: auto;
    color: #7A7F9A;
    padding-right: 10px;
    text-align: justify;
  }
  .description::-webkit-scrollbar-track {
    background-color: #E2E8F0;
    width: 4px;
  }

  .description::-webkit-scrollbar {
    width: 4px;
    background-color: #E2E8F0;
  }

  .description::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #4A5568;
  }
  .description::-webkit-scrollbar-thumb:hover {
    background-color: #cfb675;
  }
  .description::-webkit-scrollbar-track-piece{
    min-height: 250px;
  }  
}

/* Timeline */

.timeline {
  border-left: 1px solid #e3e3e3;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  background: fade($color-light, 3%);
  color: fade(white, 80%);
  font-family: $font-text;
  margin-top: 30px;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 0;
  list-style: none;
  text-align: left;
  font-weight: 100;
  h1,
  h2,
  h3 {
    font-family: $font-title;
    letter-spacing: 1.5px;
    font-weight: 100;
    font-size: 1.4em;
  }
  .event {
    border-bottom: 1px dashed fade($color-light, 10%);
    position: relative;
    // &:last-of-type {
    //     padding-bottom: 0;
    //     margin-bottom: 0;
    //     border: none;
    // }
    // &:before,
    // &:after {
    //     position: absolute;
    //     display: block;
    //     top: 0;
    // }
    // &:before {
    //     left: ((($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5) * -1;
    //     color: fade($color-light, 40%);
    //     content: attr(data-date);
    //     text-align: right;
    //     font-weight: 100;
    //     font-size: 0.9em;
    //     min-width: $date;
    // }
    // &:after {
    //     box-shadow: 0 0 0 $dotborder fade($color-primary, 100%);
    //     left: ($spacing + $line + ($dot * 0.35)) * -1;
    //     background: lighten($background, 5%);
    //     border-radius: 50%;
    //     height: $dot;
    //     width: $dot;
    //     content: "";
    //     top: 5px;
    // }
  }
}

.f-700 {
  font-weight: 700;
}

.timeline {
  li:first-child::before {
    content: '';
    display: inline-block;
    height: 0px;
    width: 0px;
    position: relative;
    left: -8px;
    top: -9px;
    border: 7px solid;
    border-bottom: 13px solid;
    border-color: transparent transparent #d0b674 transparent;
  }
  li:not(:first-child):before {
    content: '';
    display: inline-block;
    height: 11px;
    width: 11px;
    position: relative;
    left: -8px;
    top: -9px;
    border-radius: 50%;
    background: #d0b674;
  }
  .history-item::before {
    content: '';
    display: inline-block;
    height: 0px;
    width: 27px;
    position: relative;
    left: 4px;
    top: 16px;
    border: 1px dashed #a5a5a5;
  }
  .history-item {
    position: relative;
    top: -37px;
    font-size: 12px;
    .name-time {
      margin-left: 10px;
      .name {
        color: #242424;
      }
      .time {
        color: #949496;
      }
    }
    .price {
      margin-left: auto;
      .price-usdc {
        font-weight: 700;
        color: #242424;
        text-align: end;
      }
      .price-sub {
        text-align: end;
      }
    }
  }
}

.card-itembox {
  padding: 30px;
  .image-item img {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .item-content {
    margin-top: 10px !important;
    line-height: 1.5em;
    font-size: 14px;
  }
}

.colection-box {
  margin-top: 25px;
  margin-bottom: 150px;
  .colection {
    font-size: 16px;
    font-weight: 700;
    color: #242424;
  }
  .collection-img {
    width: 71px;
  }
}

.detail-item-box {
}

.popup-image {
  img {
    border-radius: 8px;
  }
  .ant-modal-content {
    background: transparent;
    box-shadow: unset;
  }
  .ant-modal-close {
    right: -20px;
    top: -30px;
    .ant-modal-close-x {
      font-size: 24px;
      color: white;
    }
    .ant-modal-close-x:hover {
      color: #e8aa0c;
    }
  }
  .ant-modal-footer {
    display: none !important;
  }
}

//response sive
@media (min-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (max-width: 767px) {
  // .card-itembox{
  //     .image-item{
  //         img{
  //             ư
  //         }
  //     }
  // }
  .detail-item-box {
    .card-itembox {
      padding: 0 !important;
    }
    .image-item {
      padding: 20px !important;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .description-and-history {
    .description-card {
      padding: 15px;
    }
  }
  .description-card,
  .history-card {
    padding: 15px !important;
  }
  .res-history {
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    .history-card {
      border-top: none;
      border-left: none;
      border-right: none;
      .ant-tabs-nav {
        margin-bottom: 0 !important;
      }
    }
  }
  .more-artist-txt {
    margin-left: 15px;
  }
  .detail-item-infor {
    margin-bottom: 20px;
    position: relative;
  }
  .res-his {
    padding-left: 0 !important;
    margin-top: 20px;
  }
}

// modal
.buy-item-checkout {
  .header-buy-item {
    border-bottom: none !important;
    height: 60px;
  }
  .body-buy-item {
  }
  .footer-buy-item {
    border-top: none !important;
    height: 60px;
  }
}

.item-inf {
  display: flex;
  .image-item {
    img {
      width: 520px;
      height: 520px;
    }
  }
  .club {
    color: #5243aa;
    font-size: 14px;
  }
}

.author-infor {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  line-height: 50px;
  border-radius: 20px;
  padding: 0 10px;
  border: 1px solid #D0D0D0;
  margin-bottom: 15px;
  .author {
    display: flex;
    align-items: center;
    line-height: 50px;
    height: 50px;
    img {
      margin-right: 8px;
    }
  }
  .address {
    display: flex;
    align-items: center;
    img {
      margin-left: 8px;
      cursor: pointer;
      &:active {
        transform: scaleX(1.1);
      }
    }
  }
}

.detail-footer {
  margin-top: 20px;
  left: 0;
  justify-content: space-between;
  width: 100%;
}

.rarity_btn_item {
  width: 110;
  background: white !important;
  margin-bottom: 10px;
}

.card-itembox {
  padding: 30px 40px !important;
}

.price-product {
  margin-top: 0;
}

.cancel-item-footer {
  display: flex;
  justify-content: space-between;
  button {
    width: 150px;
  }
}

.history-card {
  .ant-tabs-nav {
    margin-bottom: 0 !important;

  }
  .ant-tabs-content.ant-tabs-content-top {
    margin-top: 10px;
  }
}


.image-contain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-txt {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-weight: normal;
}

.campaignName {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #5243AA;
  margin-bottom: 8px;
}
.description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.mb0 {
  margin-bottom: 0 !important;
}