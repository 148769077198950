.footer-checkout {
  border-top: none !important;
  background: #f9f9f9;
  justify-content: center !important;
}

.header-checkout {
  border-bottom: none !important;
  background: #f9f9f9;
  .modal-title {
    width: 100%;
    text-align: center;
    font-weight: 600 !important;
    font-size: 25px !important;
  }
}

.recap {
  margin-top: 15px;
  margin-bottom: 20px;
  div {
    width: unset !important;
  }
}

.buy {
  height: 63px;
  background-color: #d0b674;
  border-radius: 4px;
  font-weight: bold;
  font-size: 20px;
  color: #242424;
  margin-top: 20px;
}

.body-checkout {
  padding-top: 3px !important;
  th {
    border-top: none;
  }
}

.btn-checkout {
  background: #d0b674;
  border-radius: 4px;
  width: 206px;
  height: 51px;
  font-weight: 600;
  font-size: 16px;
  color: #242424;
}

.circle {
  height: 60px;
  width: 60px;
  background-color: #89c87b;
  border-radius: 50%;
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 10px;
}

.congratulation {
  font-size: 25px;
}

.checkout-modal-success {
  flex-direction: column;
  align-items: center;
  .group-btn-checkout {
    margin-top: 38px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    button {
      width: 40%;
      border: 1px solid #cecbcb73;
      margin-right: 10px;
    }
  }
}

.w-700 {
  font-weight: 700;
}

.btn-lb {
  border-radius: 4px;
  height: 51px;
  width: 206px;
  font-weight: 600;
  font-size: 16px;
  margin-right: 20px;
  color: #242424;
  background-color: #ffffff !important;
  &:hover {
    color: #242424;
  }
}

.body-checkout {
  .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
    border-top: none;
  }
}

.btn-buy-wave.Mui-disabled {
  background: #fff;
}
.campaign-item img {
}
