.btn-lootbox {
  background: #d0b674;
  border-radius: 4px;
  height: 51px;
  font-weight: 600;
  font-size: 16px;
  color: #17204d !important;
  text-transform: uppercase;
  box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31) !important;
  border: none !important;
  float: right;
}

.btn-lt {
  background: #d0b674;
  border-radius: 4px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #242424;
}

.put-on-sale-modal {
  padding: 0px;
  color: #767676;
  font-size: 14px;
  background: #ffffff !important;
  border-radius: 8px;
  .modal-content {
    border-radius: 8px;
    padding: 40px 60px;
    border-radius: 8px;
  }
  .header-put-on-sale {
    background: unset;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    .modal-title {
      font-weight: 600;
      font-size: 32px !important;
    }
  }
  .body-put-on-sale {
    padding: 0;
    .receiver {
      justify-content: space-between;
      font-weight: 700;
      font-size: 20px;
      color: #242424;
      border-top: 1px solid #d7d7d7;
      .txt {
      }
    }
    .group-btn-sale {
      input,
      .ant-input-group-addon {
        height: 46px;
      }
      .unit-price {
        width: 80%;
        margin-right: 10px;
        svg {
          fill: #6f7072;
        }
      }
      .amount {
        width: 20%;
      }
      .lable-input {
        position: relative;
        .lable-cus {
          background: #fff;
          position: absolute;
          top: -10px;
          left: 10px;
          font-size: 12px;
          z-index: 2;
        }
      }
    }
    .image-item {
      height: 144px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    table {
      .column-2 {
        text-align: end;
      }
      margin-top: 30px;
      thead {
        th {
          font-size: 16px;
          font-weight: 700;
          color: #242424;
          border: none;
        }
      }
      th,
      td {
        border: none;
        padding-left: 0;
      }
    }
  }
  .footer-put-on-sale {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
}
