$image-width-box: 180px;
$image-width-box-sm: 90px;
.header-box-profile {
  color: #ffffff;
  width: 100%;
  position: relative;
  .header-box {
    width: 100%;
    height: 230px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-size: cover;
  }
  .box-img {
    margin-bottom: 5px;
    width: 37px;
    height: 37px;
    border: 3px dotted;
    border-bottom: none;
    position: relative;
    cursor: b;
    img {
      vertical-align: middle;
      border-style: none;
      position: absolute;
      bottom: -1px;
      right: -4px;
    }
  }
  .replace-cover {
    .replace-text {
      font-weight: 700;
      font-size: 20px;
    }
    .optimize {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
    }
  }
}

.header-box-profile {
  .replace-text {
    font-weight: 700;
    font-size: 20px;
  }
  .optimize {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
}

.header-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.image-select {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .circle-boder {
    background-image: linear-gradient(#bb00ff, #c700ff);
    // background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0.33, #743ad5), color-stop(0.67, #d53a9d));
    border: 4px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .circle-boder-2 {
    background-color: #fff;
    border: 6px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.image-select-box {
  width: $image-width-box;
  height: $image-width-box;
}

.profile-image {
  position: absolute;
  display: flex;
  bottom: -$image-width-box * 1/2;
  left: $image-width-box * 1/2;
  z-index: 9;
}

.bottom {
  height: $image-width-box * 1/2-10;
  width: $image-width-box - 20;
  border-bottom-left-radius: $image-width-box;
  border-bottom-right-radius: $image-width-box;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  flex-direction: column;
  top: $image-width-box * 1/2;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 24px;
    margin-top: -12px;
  }
  span {
    font-weight: 700;
    font-size: 13px;
  }
}

.infor-profile {
  .name {
    font-size: 32px;
    font-weight: 700px;
  }
  margin-left: 50px;
}

.profile-detail {
  margin-top: 20px;
  color: #585858;
  font-size: 14px;
  li {
    list-style: none;
  }
  .top-profile {
    display: flex;
    flex-direction: column;
    .infor {
      padding-left: 0;
      li.item {
        float: left;
        width: 95px;
        border: none;
        .number {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
  .social {
    margin-top: 20px;
    padding-left: 0;
    li {
      float: left;
      width: 40px;
    }
  }
  .content-profile {
    line-height: 36px;
  }
  .more {
    font-size: 12px;
    font-weight: 600;
    color: #242424;
  }
  .edit-btn {
    background: #d0b674;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    height: 40px;
    padding: 12px;
  }
}

.box-profile-main {
  justify-content: space-between;
  margin-top: 25px;
}

.image-div {
  box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.bongmo .card-item p {
  display: none;
}

.bongmo .image-div {
  position: relative;
  float: left;
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
.bongmo{
  .image{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .item-name{
    line-height: 20px;
    margin-top: 1rem;
  }
}

.bongmo .image-div::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #00000038;
  box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
  z-index: 1;
}

.ant-menu-sub {
  .ant-menu-item {
    float: left;
  }
  .ant-menu-item-selected {
    background: #e3e3e3 !important;
    color: #585858;
  }
  .ant-menu-item-active {
    color: #242424 !important;
    font-weight: 700 !important;
  }
}

.parent-item {
  font-size: 16px;
}

.ant-menu-item-active.parent-item {
  color: #242424 !important;
  border-bottom: 2px solid #1b0606 !important;
}

.menu-res {
  button {
    background: none;
    border: none;
    padding: 0;
    span {
      svg {
        fill: #242424 !important;
      }
    }
    &:hover {
      background: none;
    }
    &:active {
      background: none !important;
      border: none;
    }
    &:focus {
      background: none !important;
      border: none;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #1b0606 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #242424;
  font-weight: 700;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #242424 !important;
}

.ant-menu-submenu-selected {
  color: #242424 !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  counter-reset: #242424;
}

.ant-menu-submenu-title:hover {
  color: #242424 !important;
}

.ant-menu-item-selected.parent-item {
  color: #242424 !important;
  font-weight: 700;
  border-bottom: 2px solid #242424 !important;
}

.ant-menu {
  box-shadow: none;
}

.list-drop-items {
  margin-top: 10px;
  .card-item {
    box-shadow: 0px 6px 20px rgb(32 44 55 / 15%);
    border-radius: 8px;
    border: 1px solid #c1c4d0;
  }
}

@media (min-width: 1400px) {
  .parent-item {
    width: 165px;
  }
  .list-menu-res {
    display: none !important;
  }
}

//response-sive
@media (min-width: 1024px) and (max-width: 1400px) {
  .list-menu-res {
    display: none !important;
  }
  .parent-item {
    width: 120px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box-profile-menu {
    margin-top: 5px !important;
    position: absolute;
    z-index: 10;
    border: 1px solid #8080801f;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1) !important;
  }
  .button-res {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .profile-detail {
    margin-top: 40px;
    .div-hide {
      display: none;
    }
  }
  .image-select-box {
    width: $image-width-box-sm;
    height: $image-width-box-sm;
  }
  .profile-image {
    position: absolute;
    display: flex;
    bottom: -$image-width-box-sm * 1/2;
    left: $image-width-box-sm * 1/2;
  }
  .bottom {
    height: $image-width-box-sm * 1/2-10;
    width: $image-width-box-sm - 20;
    border-bottom-left-radius: $image-width-box-sm;
    border-bottom-right-radius: $image-width-box-sm;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: $image-width-box-sm * 1/2;
    justify-content: center;
    align-items: center;
    img {
      width: 19px;
      height: 16px;
      margin-top: -12px;
    }
    span {
      font-weight: 700;
      font-size: 13px;
      display: none;
    }
  }
  .profile-image {
    left: 10px;
  }
  .list-drop-items {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .list-drop-items {
    margin-top: 10px;
  }
  .box-profile-menu {
    margin-top: 5px;
    position: absolute;
    z-index: 10;
    border: 1px solid #8080801f;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1) !important;
  }
  .box-profile-main {
    margin-top: 20px;
  }
  .profile-detail {
    margin-top: 40px;
  }
  .image-select-box {
    width: $image-width-box-sm;
    height: $image-width-box-sm;
  }
  .profile-image {
    position: absolute;
    display: flex;
    bottom: -$image-width-box-sm * 1/2;
    left: $image-width-box-sm * 1/2;
  }
  .bottom {
    height: $image-width-box-sm * 1/2-10;
    width: $image-width-box-sm - 20;
    border-bottom-left-radius: $image-width-box-sm;
    border-bottom-right-radius: $image-width-box-sm;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: $image-width-box-sm * 1/2;
    justify-content: center;
    align-items: center;
    img {
      width: 19px;
      height: 16px;
      margin-top: -12px;
    }
    span {
      font-weight: 700;
      font-size: 13px;
      display: none;
    }
  }
  .profile-image {
    left: 10px;
  }
}

.ant-menu-inline {
  width: 160px !important;
}

.market-item:hover {
  // transition: all 0.3s ease 0s;
  // border: none !important;
  // box-shadow: 0px 6px 20px rgb(2 2 2 / 35%);
}

.market-item {
  // padding: 0px !important;
  // border: 1px solid #c1c4d0 !important;
  // box-shadow: 0px 6px 20px rgba(32, 44, 55, 0.15);
  border-radius: 8px;
  .item {
    padding: 0px !important;
    border: unset !important;
  }
  .card-item > div {
    // padding: 10px 0px !important;
  }
  .card {
    // border: unset !important;
    background: transparent;
    img {
      // box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
      box-shadow: 0px 3px 8px -1px rgb(0 39 85 / 6%), 0px 2px 5px rgb(0 39 85 / 15%);
      border-radius: 8px;
    }
    .card-body {
      padding: 20px 0px;
      .name {
        font-weight: 600;
        line-height: 2em;
        color: #42496e;
        font-size: 16px;
        width: 100%;
      }
      .owner {
        font-weight: 400;
        line-height: 16px;
        color: gray;
        font-size: 16px;
        img {
          width: 16px;
          margin-right: 10px;
        }
      }
      .price {
        font-weight: 700;
        font-size: 16px;
        color: #c9a138;
        float: right;
        line-height: 2em;
        font-weight: bold;
      }
      .pice-div {
        border-top: 1px solid #d4d4d4;
      }
      .price-title {
        font-size: 14px;
        line-height: 2em;
        float: left;
        margin-top: 10px;
        color: #7a7f9a;
        font-weight: bold;
      }
      .days {
        color: #949496;
        font-size: 14px;
      }
    }
  }
  // .rarity_btn {
  //     border-radius: 10px !important;
  //     height: 20px !important;
  //     padding: 0px 5px;
  //     line-height: 1em !important;
  //     font-size: 12px !important;
  //     float: left;
  //     background: transparent !important;
  // }
  .number_edition_btn {
    border-radius: 25px !important;
    height: 24px !important;
    padding: 4px 8px;
    line-height: 0px !important;
    font-size: 14px !important;
    float: right;
    color: #5e6484;
    background: #f4f5f7;
  }
  // .rarity_legend {
  //     color: #FFD148 !important;
  //     border-color: #FFD148 !important;
  // }
  // .rarity_epic {
  //     color: #FC51FF !important;
  //     border-color: #FC51FF !important;
  // }
  // .rarity_super_rare {
  //     color: #61C4F7 !important;
  //     border-color: #61C4F7 !important;
  // }
  // .rarity_rare {
  //     color: #00E742 !important;
  //     border-color: #00E742 !important;
  // }
  // .rarity_common {
  //     color: #D8D8D8 !important;
  //     border-color: #D8D8D8 !important;
  // }
}

.btn-hanlde-lb {
  border-radius: 20px !important;
}

.btn-hanlde-lb.active {
  background: #201934;
  color: white;
}

.group-btn-sort {
  .ant-space-item {
    border: 1px solid #80808036;
    border-radius: 24px;
    overflow: hidden;
    padding: 0px 12px;
  }
}

.pice-div {
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding-top: 10px !important;
  .price-title {
    flex: 1;
    white-space: nowrap;
    margin-top: 0 !important;
  }
  .price {
    font-size: 16px;
    color: #c9a138;
    margin-top: 0 !important;
  }
}

.list-item-propup {
  // margin-top: 20px !important;
  background: #dfe0e6;
  padding: 20px 10px !important;
  border-radius: 3px;
}

.body-profile {
  margin-bottom: 30px;
}

.drop-group {
  position: relative;
  margin-bottom: 20px;
  padding: 0 8px !important;
  .arrow-item {
    position: absolute;
    bottom: -30px;
    right: 50%;
    transform: translateX(50%);
  }
}
