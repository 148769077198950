// .list-drop-items {
//     margin-top: 70px;
// }
.box-profile-page {
  .body-profile {
    position: relative;
    .left {
      .ant-card-hoverable,
      .ant-card-hoverable:hover {
        box-shadow: unset;
      }
    }
    .card-avatar {
      max-width: 100%;
      width: 280px;
      box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);
      margin-bottom: 30px;
      text-align: center !important;
      position: absolute;
      top: -140px;
      padding-top: 40px !important;
      border: unset;
      background-color: white;
      padding-left: 40px;
      padding-right: 40px;
      > div {
        max-width: 100%;
      }
      .ant-card-bordered {
        border: unset;
      }
      img {
        width: 200px;
        height: 200px;
        max-width: 100%;
        display: inline;
        border-radius: 50%;
      }
      h3 {
        font-size: 24px !important;
        color: #091342;
        padding-bottom: 20px;
        font-weight: 600;
        word-break: break-all;
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
        color: #42496e;
      }
      .CopyToClipboard {
        cursor: pointer;
        width: 100%;
        text-align: center;
        margin-bottom: 1em;
        color: #5e6484;
        cursor: pointer;
        padding: 0px;
        * {
          flex: 1;
        }
        img {
          max-width: 12px !important;
          height: 12px !important;
          line-height: 24px;
          display: inline-block;
          margin-left: 10px;
          border-radius: 0px;
        }
        span:hover {
          color: blue;
        }
      }
    }
  }
}

.ant-card-cover {
  display: flex;
  justify-content: center;
}

@media (max-width: 1100px) {
  .card-avatar {
  }
}
