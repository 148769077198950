.product-detail-page {
  background-color: #f9f9f9;
  font-size: 14px;
  padding-top: 30px;
  .container {
    padding-bottom: 50px;
  }
  .table thead th,
  .table thead tr {
    border: none !important;
  }
}
.btn-handle {
  border-radius: 50px !important;
}
.series-content {
  .ant-card-body {
    width: 100%;
    padding: 10px;
  }
  td{
    border: none;
  }
  tr td + td, tr th + th{
    text-align: right;
  }
  tr{
    height: 40px;
    th:first-child{
      padding-left: 40px !important;
    }      
    th:last-child{
      padding-right: 40px !important;
    }     
    td:first-child, td:last-child{
      padding-left: 40px !important;
    }      
    td:last-child{
      padding-right: 40px !important;
    }
  }
  .footer-row{
    background-color: #F4F5F7;
    padding: 12px 40px;
    td{
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.btn-acctive {
  background: #201934 !important;
  color: #fff !important;
}
