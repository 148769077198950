.item-detail-page {
  background-color: #f9f9f9;
  font-size: 14px;
  padding-top: 30px;
  color: 585858;
  .card {
    border-radius: 1px;
  }
}

.detail-item-infor {
  background-color: white;
  border-radius: 8px;
  padding: 30px !important;
  border: 1px solid #eaecef;
  box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);
  .title {
    flex-direction: column;
    margin-bottom: 21px;
    .subject {
      font-weight: 600;
      color: #242424;
      font-size: 32px;
      line-height: 2em;
    }
    .available {
      padding-bottom: 10px;
      border-bottom: 1px solid #e3e3e3;
    }
  }
  .page-infor {
    margin-bottom: 10px;
    .name-follow {
      margin-left: 10px;
      .name {
        font-size: 16px;
      }
    }
  }
  // .item-content {
  //     min-height: 150px;
  // }
  .price-product {
    .price-txt {
      font-size: 16px;
      font-weight: bold;
      color: #949496;
    }
    .price-value {
      margin-left: auto;
      flex-direction: column;
    }
    .price-number {
      font-size: 25px;
      font-weight: 700;
    }
    .price-sub-number {
      text-align: end;
    }
  }
}

.f-700 {
  font-weight: 700;
}

.c-24 {
  color: #242424;
}



.description-and-history {
  margin-top: 20px;
  margin-bottom: 60px;
  .ant-tabs-tab + .ant-tabs-tab{
    margin-left: 8px !important;
  }
  .description-card {
    padding: 40px 40px 50px 40px;
    .description-text {
      font-size: 20px;
      margin-bottom: 18px;
    }
    .box-infor-des {
      margin-bottom: 17px;
      .name-box {
        margin-left: 10px;
      }
      .name {
        font-size: 16px;
      }
    }
    .description-content {
      .txt {
        color: #000;
      }
      span {
        line-height: 28px;
      }
      .rarity {
        background-color: #f8e600;
        /* height: 30px; */
        display: inline-block;
        padding: 0 10px;
        border-radius: 2px;
        font-weight: 600;
      }
      .content {
        margin-top: 20px;
      }
    }
  }
  .history-card {
    padding: 10px 30px 30px 30px;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #eaecef;
    overflow: hidden;
    .history-title {
      border-bottom: 1px solid #e3e3e3;
      font-weight: 600;
      span {
        font-size: 16px;
        font-weight: 600;
        color: #242424;
        display: inline-block;
        height: 40px;
        text-align: center;
        width: 80px;
        border-bottom: 2px solid #242424;
      }
    }
    .ant-tabs-content-holder::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // border-radius: 100px;
      background-color: #E2E8F0;
      width: 4px;
      margin-bottom: -170px;
    }

    .ant-tabs-content-holder::-webkit-scrollbar {
      width: 4px;
      background-color: #E2E8F0;
    }

    .ant-tabs-content-holder::-webkit-scrollbar-thumb {
      border-radius: 8px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #4A5568;
      border-bottom: 170px solid #E2E8F0 /*or any height and color you want*/;
    }
    .ant-tabs-content-holder::-webkit-scrollbar-thumb:hover {
      background-color: #cfb675;
    }
    .ant-tabs-content-holder::-webkit-scrollbar-track-piece{
      min-height: 250px;
    }

  }
}
.list-history {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  > ul {
    padding: 20px 5px;
    .ant-timeline-item-content {
      top: -15px !important;
    }
    .name-time {
      width: 60%;
      word-break: break-all;
      overflow: hidden;
      height: 60px !important;
      padding: 0 10px;
    }
    .time {
      color: #949496;
    }
    .price {
      width: calc(40% - 10px);
      text-align: right;
    }
    .price-usdc {
      font-weight: bold;
    }
    .price-sub {
      color: #949496;
    }
    > li .ant-timeline-item-head {
      border-color: #d0b674;
      background-color: #d0b674;
    }
    > li:first-child .ant-timeline-item-head {
      background-color: transparent;
      border-color: transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #d0b674;
      left: -3px;
    }
  }
}
