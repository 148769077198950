.slide-campaign-wrapper {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  padding-top: 30px;
}

.slide-campaign-wrapper .slick-slider {
  width: 100%;
}

.slider-large .slick-slider {
  width: 120% !important;
}


.slide-campaign-wrapper .slider {
  /* width: 120%; */
  margin: 0 -10px !important;
}

.slide-campaign-wrapper .slider .campaign-item {
  padding: 10px;
  border: unset;
  min-height: unset !important;
}
.slide-campaign-wrapper .campaign-item .soldout-bg {
  background: #2019342b;
  position: relative;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.slide-campaign-wrapper .campaign-item .soldout-bg .campaign-image-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slide-campaign-wrapper .campaign-item .soldout-bg::after {
  content: '';
  display: block;
  padding-bottom: 37.74%;
}
.slide-campaign-wrapper .campaign-item .soldout-bg::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

/*.slide-campaign-wrapper .campaign-item .soldout-bg::before{
	content:  '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);;
	top: 0px;
	left: 0px;
}*/

.slide-campaign-wrapper .slider .campaign-item img {
  width: 100%;
}
.slide-campaign-wrapper h3 {
  font-weight: bold;
  color: #17204d;
  font-size: 20px;
  text-transform: uppercase;
}
.slide-campaign-wrapper .slick-arrow {
  display: none !important;
}
.slide-campaign-wrapper .next-button {
  position: absolute;
  top: calc(50% + 5px);
  right: 5px;
  padding: 10px;
}
.slide-campaign-wrapper .next-button .next-icon {
  font-size: 18px;
  color: white;
  background: #c5c5c596;
  padding: 8px;
}
.slide-campaign-wrapper .item-info {
  position: absolute;
  width: 100%;
  top: calc(50% - 50px);
  text-align: center;
  color: white;
  z-index: 3;
  display: flex;
  justify-content: center;
}
.slide-campaign-wrapper .item-info .item-name {
  font-size: 32px;
  line-height: 2em;
  text-align: center;
}
.slide-campaign-wrapper .item-info .text-soldout {
  font-size: 18px;
  border: 1px solid white;
  padding: 7px 30px;
  border-radius: 40px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  font-weight: bold;
}
.campain-page .banner::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000000e3;
}
